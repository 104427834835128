import React, { ReactNode } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'src/redux_store';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';

import DialogWrapper from '../dialog_wrapper';
import BootstrapDialogTitle from '../dialog_title';
import { useStyles } from './styles';

interface ILeaveSiteDialog {
  modalId: string;
  describe: ReactNode;
  dialogTitle?: string;
  cancelLabel?: string;
  okLabel?: string;
  callback: () => any;
  prevClose?: () => any;
}

function LeaveSiteDialog(props: ILeaveSiteDialog) {
  const { t } = useTranslation();
  const {
    modalId,
    describe,
    dialogTitle = t('message.leaveSiteDialogTitle'),
    cancelLabel = t('button.staySite'),
    okLabel = t('button.continue'),
    callback,
    prevClose,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
    if (prevClose) {
      prevClose();
    }
  };

  return (
    <DialogWrapper modalId={modalId} prevClose={handleClose}>
      <Box className={classes.dialog}>
        <BootstrapDialogTitle onClose={handleClose}>{dialogTitle} ?</BootstrapDialogTitle>
        <Box p={2}>
          <Typography>{describe}</Typography>
        </Box>
        <Box className={classes.actionButton}>
          <Button color="primary" onClick={handleClose}>
            {cancelLabel}
          </Button>
          <Button variant="contained" color="primary" onClick={callback}>
            {okLabel}
          </Button>
        </Box>
      </Box>
    </DialogWrapper>
  );
}

export default LeaveSiteDialog;
