import React from 'react';

import AdminLayout from 'src/layout/admin_layout';
import AuthLayout from 'src/layout/auth_layout';

const NotFound = React.lazy(() => import('src/components/not_found'));
const Login = React.lazy(() => import('src/pages/login'));
// const Map = React.lazy(() => import('src/pages/map'));
// const CameraMap = React.lazy(() => import('src/pages/camera_map'));
const StationMap = React.lazy(() => import('src/pages/station_map'));
const Register = React.lazy(() => import('src/pages/register'));
const CameraManagement = React.lazy(() => import('src/pages/camera_management'));
const UserManagement = React.lazy(() => import('src/pages/user_management'));
const UserManagementDetail = React.lazy(
  () => import('src/pages/user_management/user_list/user_information'),
);
const StationManagement = React.lazy(() => import('src/pages/station_management'));
const Watchlist = React.lazy(() => import('src/pages/watchlist'));
// const SystemManagement = React.lazy(() => import('src/pages/system_management'));
const CustomerManagement = React.lazy(() => import('src/pages/customer_management'));
const CustomerDetail = React.lazy(() => import('src/pages/customer_management/customer_detail'));
const OrderDetail = React.lazy(
  () => import('src/pages/customer_management/customer_detail/orders/order_detail'),
);
const RoleDetail = React.lazy(() => import('src/pages/user_management/role_list/role_detail'));

import { IRoute } from 'src/types/route';
import { PATH } from './path';
import NotificationManagement from 'src/pages/notification';
import { EPermissions } from 'src/types/enum';
import StationDetail from 'src/pages/station_detail';

export const ROUTE_LIST: IRoute[] = [
  // {
  //   path: PATH.cameraMap,
  //   name: 'common.routes.cameraMap',
  //   component: CameraMap,
  //   layout: AdminLayout,
  //   permission: [
  //     EPermissions.GET_LIST_CUSTOMER,
  //     EPermissions.GET_LIST_STATION,
  //     EPermissions.GET_LIST_CAMERA,
  //   ],
  //   isHaveRoute: true,
  // },
  {
    path: PATH.stationMap,
    name: 'routes.stationMap',
    component: StationMap,
    layout: AdminLayout,
    permission: [
      EPermissions.GET_LIST_CUSTOMER,
      EPermissions.GET_LIST_STATION,
      EPermissions.GET_LIST_CAMERA,
    ],
    isHaveRoute: true,
  },
  {
    path: PATH.stationDetail,
    name: 'routes.stationDetail',
    component: StationDetail,
    layout: AdminLayout,
    permission: [EPermissions.GET_LIST_STATION, EPermissions.GET_LIST_CUSTOMER],
    isHaveRoute: true,
  },
  {
    path: PATH.watchList,
    name: 'routes.watchList',
    component: Watchlist,
    layout: AdminLayout,
    permission: [
      EPermissions.GET_LIST_CUSTOMER,
      EPermissions.GET_LIST_STATION,
      EPermissions.GET_LIST_CAMERA,
    ],
    isHaveRoute: true,
  },
  {
    path: PATH.stationManagement,
    name: 'routes.stationManagement',
    component: StationManagement,
    layout: AdminLayout,
    isHaveRoute: true,
    permission: [EPermissions.GET_LIST_STATION],
  },
  {
    path: PATH.cameraManagement,
    name: 'routes.cameraManagement',
    component: CameraManagement,
    layout: AdminLayout,
    permission: [EPermissions.GET_LIST_CAMERA],
    isHaveRoute: true,
  },
  {
    path: PATH.userManagement,
    name: 'routes.usersManagement',
    component: UserManagement,
    layout: AdminLayout,
    permission: [EPermissions.LIST_USER],
    isHaveRoute: true,
  },
  {
    path: PATH.userManagementDetail,
    name: 'routes.usersManagement',
    component: UserManagementDetail,
    layout: AdminLayout,
    permission: [EPermissions.LIST_USER],
    isHaveRoute: true,
  },
  {
    path: PATH.roleManagementDetail,
    name: 'routes.usersManagement',
    component: RoleDetail,
    layout: AdminLayout,
    permission: [EPermissions.LIST_USER],
    isHaveRoute: true,
  },
  {
    path: PATH.login,
    name: 'routes.login',
    component: Login,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  // {
  //   path: PATH.register,
  //   name: 'routes.userRegistration',
  //   component: Register,
  //   layout: AuthLayout,
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.systemManagement,
  //   name: 'routes.systemManagement',
  //   component: SystemManagement,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  // },
  {
    path: PATH.customerManagement,
    name: 'routes.customerManagement',
    component: CustomerManagement,
    layout: AdminLayout,
    isHaveRoute: true,
    permission: [EPermissions.GET_LIST_CUSTOMER],
  },
  {
    path: PATH.customerDetail,
    name: 'routes.customerManagement',
    component: CustomerDetail,
    layout: AdminLayout,
    isHaveRoute: true,
    permission: [EPermissions.GET_LIST_CUSTOMER],
  },
  {
    path: PATH.customerOrder,
    name: 'routes.customerManagement',
    component: OrderDetail,
    layout: AdminLayout,
    isHaveRoute: true,
    permission: [EPermissions.LIST_ORDER],
  },
  {
    path: PATH.notification,
    name: 'routes.notification',
    component: NotificationManagement,
    layout: AdminLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.notFound,
    name: 'routes.notFound',
    component: NotFound,
    isHaveRoute: true,
  },
];
