import { Menu } from '@mui/icons-material';
import { Box, Divider, MenuItem, Tooltip } from '@mui/material';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { MENU_LIST } from 'src/routes/menu';
import { IMenuRoute } from 'src/types/route';
import { ButtonMobile, ButtonTop, MenuMobile, useStyles } from './styles';
import { useAppSelector } from 'src/redux_store';
import { ROLE_LEVEL } from 'src/constants/role';

interface IProps {
  toggleSidebar: () => void;
}

const SidebarSmall = ({ toggleSidebar }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { role } = useAppSelector((state) => state.myAccountSlice);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [open, setOpen] = React.useState<{
    [x: string]: boolean;
  }>({});

  const handleOpen = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorEl(event.currentTarget);
    setOpen({ [name]: true });
  };
  const handleClose = (name: string) => {
    setAnchorEl(null);
    setOpen({ [name]: false });
  };

  const renderIcon = (menu: IMenuRoute) => {
    let Icon;

    if (location.pathname === menu.path) {
      Icon = menu.activeIcon;
    } else {
      Icon = menu.icon;
    }

    return <Icon color={location.pathname === menu.path ? 'primary' : 'default'} />;
  };

  const renderMenuList = () => {
    return MENU_LIST.map((menu, index) => {
      if (!menu.isHaveMenu) return;
      const permissions = role.permissions || [];
      const isShow = _.isEmpty(_.intersection(menu.permission, permissions));

      if ((role.level !== ROLE_LEVEL.SUPER_ADMIN && isShow && menu.permission) || !menu.isHaveMenu)
        return;

      return (
        <React.Fragment key={index}>
          <Box className={classes.listItem} onClick={(e) => handleOpen(e, menu.id)}>
            <Tooltip title={t(menu.id)} arrow placement="right">
              <ButtonMobile
                onClick={() => menu?.path && navigate(menu.path)}
                className={classNames({
                  active:
                    location.pathname === menu.path ||
                    !_.isEmpty(menu?.subMenu?.find((item) => location.pathname === item.path)),
                })}
              >
                {renderIcon(menu)}
              </ButtonMobile>
            </Tooltip>
          </Box>
          {menu?.subMenu && (
            <MenuMobile
              id={menu.path}
              open={Boolean(open?.[menu.id])}
              anchorEl={anchorEl}
              elevation={2}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => menu?.path && handleClose(menu.path)}
            >
              {menu.subMenu?.map((item, index) => (
                <MenuItem
                  className={classNames({
                    active_menu: item.path === location.pathname,
                  })}
                  onClick={() => {
                    handleClose(menu.id);
                    navigate(item.path);
                  }}
                  key={index}
                >
                  {t(item.id)}
                </MenuItem>
              ))}
            </MenuMobile>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Tooltip title={t('button.expand.sidebar')} arrow placement="right">
          <ButtonTop onClick={toggleSidebar} className={classes.topButton}>
            <Menu />
          </ButtonTop>
        </Tooltip>
      </Box>
      <Divider />
      <Box className={classes.top}>
        <Scrollbars>
          <Box>{renderMenuList()}</Box>
        </Scrollbars>
      </Box>

      {/* <Box>
        <Box className={classes.bottomButton}>
          <Tooltip title={t('common.routes.systemManagement')} arrow placement="right">
            <ButtonMobile
              className={classNames({
                active: location.pathname === PATH.systemManagement,
              })}
              onClick={() => navigate(PATH.systemManagement)}
            >
              {location.pathname === PATH.systemManagement ? <Settings /> : <SettingsOutlined />}
            </ButtonMobile>
          </Tooltip>
        </Box>
      </Box> */}
    </Box>
  );
};

export default SidebarSmall;
