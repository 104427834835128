import React, { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import DialogWrapper from 'src/components/modal/dialog_wrapper';
import { MODAL_IDS } from 'src/constants/modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import { useTranslation } from 'react-i18next';
// import { updateAvatar } from 'src/redux_store/my_account/my_account_action';
import { useIsRequestPending } from 'src/hooks';
import { LoadingButton } from '@mui/lab';

const ModalChangeAvatar = () => {
  const [file, setFile] = useState<any>(null);
  const { me } = useAppSelector((state) => state.myAccountSlice);
  const [urlAvatar, setUrlAvatar] = useState<string>(me.avatarUrl || '');
  const isLoading = useIsRequestPending('myAccount', 'updateAvatar');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(closeModal({ modalId: MODAL_IDS.changeAvatar }));
  };

  const handleUpload = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
    setUrlAvatar(URL.createObjectURL(file));
  };

  const handleUpdateAvatar = (event: any) => {
    console.log('object');
    // const formData = new FormData();
    // formData.append('files', file);
    // dispatch(updateAvatar(formData))
    //   .unwrap()
    //   .then((data) => {
    //     data.forEach((item) => {
    //       // setUrlImage(item.path);
    //       // if (item.id) {
    //       //   dispatch(addIdImage(item.id));
    //       // }
    //     });

    //     // toastMessage.success('Thêm ảnh thành công');
    //   });
  };

  return (
    <DialogWrapper
      sx={{
        width: '500px',
        padding: '10px 20px',
      }}
      modalId={MODAL_IDS.changeAvatar}
    >
      <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontSize={'21px'}>{t('account.accountInfo.modal.avatarEditTitle')}</Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <Button variant="text" component="label" startIcon={<AddOutlinedIcon />}>
        {t('common.btnName.uploadImage')}
        <input accept="image/*" type="file" onChange={handleUpload} hidden />
      </Button>
      <Box width={'100%'} height={'350px'} mt={1}>
        <img
          src={urlAvatar}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          alt=""
        />
      </Box>
      <Box sx={{ display: 'flex', marginTop: '10px', gap: '10px', padding: '5px 0' }}>
        <LoadingButton
          loading={isLoading}
          disabled={file ? false : true}
          variant="outlined"
          onClick={handleUpdateAvatar}
        >
          {t('label.apply')}
        </LoadingButton>
        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
          {t('button.cancel')}
        </Button>
      </Box>
    </DialogWrapper>
  );
};

export default ModalChangeAvatar;
