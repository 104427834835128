import mix from 'src/utils/mix';
import ClientBase from './base';
import ClientBlacklist, { ClientBlacklistMix } from './blacklist';
import ClientCamera, { ClientCameraMix } from './camera';
import ClientClassified, { ClientClassifiedMix } from './classified_list';
import ClientCustomer, { ClientCustomerMix } from './customer';
import ClientMyAccount, { ClientMyAccountMix } from './my_account';
import ClientNotification, { ClientNotificationMix } from './notification';
import ClientPackages from './packages';
import ClientPermission, { ClientPermissionMix } from './permission';
import ClientPolitical, { ClientPoliticalMix } from './political';
import ClientPtz, { ClientPtzMix } from './ptz';
import ClientRole, { ClientRoleMix } from './role';
import ClientStation, { ClientStationMix } from './station';
import ClientSystem, { ClientSystemMix } from './system';
import ClientUser, { ClientUserMix } from './user';
import ClientVideo, { ClientVideoMix } from './video';

interface Client
  extends ClientBase,
    ClientPoliticalMix,
    ClientCameraMix,
    ClientRoleMix,
    ClientPermissionMix,
    ClientUserMix,
    ClientPtzMix,
    ClientStationMix,
    ClientCustomerMix,
    ClientVideoMix,
    ClientClassifiedMix,
    ClientSystemMix,
    ClientMyAccountMix,
    ClientNotificationMix,
    ClientBlacklistMix {}

class Client extends mix(ClientBase).with(
  ClientPackages,
  ClientPolitical,
  ClientCamera,
  ClientRole,
  ClientPermission,
  ClientUser,
  ClientPtz,
  ClientStation,
  ClientCustomer,
  ClientVideo,
  ClientClassified,
  ClientSystem,
  ClientMyAccount,
  ClientNotification,
  ClientBlacklist,
) {
  constructor() {
    super();
  }
}

const client = new Client();

export default client;
