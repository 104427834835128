import React from 'react';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import notFount from '../../assets/images/not_found.png';

import { useStyles } from './styles';
import { PATH } from 'src/routes/path';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <img
          src={notFount}
          alt="notFount"
          style={{
            width: '400px',
          }}
        />
        <Typography variant="h6" sx={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Roboto' }}>
          {t('label.pageNotFound.main')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography>{t('label.pageNotFound.description')}</Typography>
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(PATH.customerManagement);
              }}
            >
              <KeyboardReturnOutlinedIcon sx={{ marginRight: '5px' }} /> {t('label.goHomePage')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
