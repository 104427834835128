import { IUser } from 'src/types/auth';
import { IPagination } from 'src/types/common';
import { IUpdateActive, IUpdateUserInfo, IUserFilter } from 'src/types/user';
import ClientBase from './base';
import { IRole } from 'src/types/role';
import { ICustomer } from 'src/types/customer';

const userRouter = 'http://10.49.46.54:9003/api/v1/admin/users';

export interface ClientUserMix {
  getUsers: (data: IUserFilter) => Promise<IPagination<IUser>>;
  getUserInfo: (userId: string) => Promise<IUser>;
  updateActive: ({ userId, data }: IUpdateActive) => Promise<{ message: string }>;
  updateUserRole: (userId: string, roleId: string) => Promise<IRole>;
  updateUserInfo: ({ userId, data }: { userId: string; data: IUpdateUserInfo }) => Promise<IUser>;
  deleteUser: (userId: string) => Promise<{ message: string }>;
  verifyEmailUser: ({
    userId,
    email,
  }: {
    userId: string;
    email: string;
  }) => Promise<{ message: string }>;
  revokeAllUserSessions: (userId: string) => Promise<{ message: string }>;
  inviteUsers: (data: { userId: string; email: string; roleId: string }) => Promise<string>;
  getListCustomersWhichUser: (userId: string) => Promise<ICustomer[]>;
}

const ClientUser = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientUserMix {
    getUsers = async (data: IUserFilter) => {
      return this.doFetch<IPagination<IUser>>(`${this.getUserRoute()}/search`, {
        method: 'post',
        data: data,
      });
    };
    getUserInfo = async (userId: string) => {
      return this.doFetch<IUser>(`${this.getUserRoute()}/${userId}`, {
        method: 'get',
      });
    };
    revokeAllUserSessions = async (userId: string) => {
      return this.doFetch<{ message: string }>(
        `${this.getUserRoute()}/${userId}/sessions/revoke/all`,
        {
          method: 'post',
          data: null,
        },
      );
    };
    updateUserInfo = async ({ userId, data }: { userId: string; data: IUpdateUserInfo }) => {
      return this.doFetch<IUser>(`${this.getUserRoute()}/${userId}/general`, {
        method: 'put',
        data,
      });
    };
    updateUserRole = async (userId: string, roleId: string) => {
      return this.doFetch<IRole>(`${this.getUserRoute()}/${userId}/role`, {
        method: 'put',
        data: { roleId },
      });
    };
    updateActive = async ({ userId, data }: IUpdateActive) => {
      return this.doFetch<{ message: string }>(`${this.getUserRoute()}/${userId}/active`, {
        method: 'put',
        data,
      });
    };
    deleteUser = async (userId: string) => {
      return this.doFetch<{ message: string }>(`${this.getUserRoute()}/${userId}`, {
        method: 'delete',
      });
    };
    verifyEmailUser = async ({ userId, email }: { userId: string; email: string }) => {
      return this.doFetch<{ message: string }>(
        `${this.getUserRoute()}/${userId}/email/verify/send`,
        {
          method: 'post',
          data: email,
        },
      );
    };
    inviteUsers = async (data: { userId: string; email: string; roleId: string }) => {
      return this.doFetch<string>(`${this.getUserRoute()}/invite`, {
        method: 'post',
        data: data,
      });
    };
    getListCustomersWhichUser = async (userId: string) => {
      return this.doFetch<ICustomer[]>(`${this.getUserRoute()}/${userId}/customers`, {
        method: 'get',
      });
    };
  };

export default ClientUser;
