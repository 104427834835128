import _ from 'lodash';
import { ROLE_LEVEL } from 'src/constants/role';
import { MENU_LIST } from 'src/routes/menu';
import { PATH } from 'src/routes/path';
import { IUser } from 'src/types/auth';
import { ECameraStatus, ICameraFeature } from 'src/types/camera';
import { TVehicleType } from 'src/types/events';
import { IRole } from 'src/types/role';
import { EStationStatus } from 'src/types/station';

import stationIconDisable from 'src/assets/icons/station_marker_disable.png';
import stationIconError from 'src/assets/icons/station_marker_error.png';
import stationIconSuccess from 'src/assets/icons/station_marker_success.png';
import stationIconWarning from 'src/assets/icons/station_marker_warning.png';
import { isStationLevelWarning } from './station';

export const convertMinMaxToArray = (
  min: number | string,
  max: number | string,
): { value: number; label: string }[] => {
  if (min && max) {
    return Array.from({ length: Number(max) - Number(min) + 1 }, (v, k) => {
      return {
        value: Number(k + Number(min)),
        label: String(k + Number(min)),
      };
    });
  }

  return [];
};

export const checkDrawZone = (features: ICameraFeature[]) => {
  const noDrawList = features.map((feature) => {
    const isCheckNoDraw = feature.zones.every((zone) => zone.vertices.length === 0);
    return isCheckNoDraw;
  });

  return !noDrawList.every((isDraw) => isDraw === false);
};

export const formatTimeZone = (
  timeArray: {
    start:
      | {
          hour: number;
          minute: number;
        }
      | Date;
    end:
      | {
          hour: number;
          minute: number;
        }
      | Date;
  }[],
) => {
  if (!timeArray?.length) return [];
  const result = timeArray.map((time) => {
    const { start, end } = time;

    let hourStart, hourEnd, minuteStart, minuteEnd;
    if (start instanceof Date) {
      hourStart = start.getHours();
      minuteStart = start.getMinutes();
    } else {
      hourStart = start.hour;
      minuteStart = start.minute;
    }
    if (end instanceof Date) {
      hourEnd = end.getHours();
      minuteEnd = end.getMinutes();
    } else {
      hourEnd = end.hour;
      minuteEnd = end.minute;
    }

    return {
      start: {
        hour: hourStart,
        minute: minuteStart,
      },
      end: {
        hour: hourEnd,
        minute: minuteEnd,
      },
    };
  });

  return result;
};

export const formatVehicle = (vehicleArr: (TVehicleType | number)[]) => {
  if (!vehicleArr?.length) return [];

  return vehicleArr.map((vehicle) => (typeof vehicle === 'number' ? vehicle : vehicle.id));
};

export const getParamToNavigate = (me: IUser, role: IRole) => {
  let from = PATH.login;

  if (!_.isEmpty(me) && !_.isEmpty(role)) {
    const permissions = role.permissions || [];

    if (role.level === ROLE_LEVEL.SUPER_ADMIN || role.level === ROLE_LEVEL.SYSTEM_ADMIN) {
      from = PATH.customerManagement;
    } else {
      for (const menu of MENU_LIST) {
        if (!_.isEmpty(menu?.permission)) {
          const isHavPermission = _.isEmpty(_.intersection(menu.permission, permissions));
          if (!isHavPermission && menu.isHaveMenu) {
            from = menu.path as string;
            break;
          } else {
            continue;
          }
        } else {
          from = menu.path as string;
          break;
        }
      }
    }
  }

  return from;
};

export const getCameraIconMarker = (status: ECameraStatus) => {
  const icons: { [key in ECameraStatus]: string } = {
    [ECameraStatus.NORMAL]: require('src/assets/icons/cctv-success.png'),
    [ECameraStatus.ERROR]: require('src/assets/icons/cctv-error.png'),
    [ECameraStatus.WARNING]: require('src/assets/icons/cctv-warning.png'),
    [ECameraStatus.OFFLINE]: require('src/assets/icons/cctv-disconnect.png'),
  };

  return icons[status];
};

export const getStationIconMap = (status: string) => {
  if (status === EStationStatus.ERROR) {
    return stationIconError;
  }

  if (status === EStationStatus.OFFLINE) {
    return stationIconDisable;
  }

  if (isStationLevelWarning(status as EStationStatus)) {
    return stationIconWarning;
  }

  if (status === EStationStatus.NORMAL) {
    return stationIconSuccess;
  }

  return stationIconDisable;
};

export const convertArrayString = <T>(arrayId: string[], array: any[], key: string): T[] => {
  const result: T[] = [];
  arrayId.forEach((id) => {
    const item = array.find((it) => String(it[key]) === String(id));
    if (item) {
      result.push(item);
    }
  });
  return result;
};
