import React from 'react';
import { Blocker, History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { Navigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isBoolean } from 'lodash';

import { MODAL_IDS } from 'src/constants/modal';
import { changeIsDirty } from 'src/redux_store/common/prompt/prompt_slice';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { closeModal, openModal } from 'src/redux_store/common/modal/modal_slice';
import LeaveSiteDialog from 'src/components/modal/leave_site_dialog';

function useBlocker(blocker: Blocker, condition?: boolean) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!condition) return;
    const unblock = (navigator as Navigator & Pick<History, 'block'>).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, condition]);
}

interface IUsePromptProps {
  isDirty?: boolean;
  message?: string;
  actionType: 'add' | 'edit' | 'unknown';
  isSubmitted?: boolean;
  reset?: any;
  getValues?: any;
  prevClose?: any;
}

export function usePrompt(props: IUsePromptProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDirty, message, isSubmitted, actionType, reset, getValues, prevClose } = props;
  const { isDirty: isDirtyState } = useAppSelector(({ promptSlice }) => promptSlice);

  useEffect(() => {
    if (isSubmitted && reset && getValues) {
      reset(getValues());
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (!isBoolean(isDirty)) return;
    dispatch(changeIsDirty(isDirty));
  }, [isDirty]);

  const formatMessage = () => {
    let result = message;
    if (message) return result;

    switch (actionType) {
      case 'add':
        result = t('message.confirmLeaveSiteWithAddType');
        break;
      case 'edit':
        result = t('message.confirmLeaveSiteWithEditType');
        break;
      case 'unknown':
        result = message;
        break;
      default:
        result = message;
        break;
    }

    return result;
  };

  const acceptPrompt = (tx?: Transition, callback?: () => void) => {
    dispatch(closeModal({ modalId: MODAL_IDS.unmountConfirm }));
    dispatch(changeIsDirty(false));
    if (tx) {
      tx.retry();
    }
    if (callback) {
      callback();
    }
  };

  const handleOpenModal = (tx?: Transition, callback?: () => void) => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.unmountConfirm,
        dialogComponent: (
          <LeaveSiteDialog
            modalId={MODAL_IDS.unmountConfirm}
            describe={formatMessage()}
            callback={() => acceptPrompt(tx, callback)}
            prevClose={prevClose}
          />
        ),
      }),
    );
  };

  const blocker = useCallback(
    (tx?: Transition) => {
      handleOpenModal(tx);
    },
    [message],
  );

  const preventUnmount = useCallback(
    (callback: () => void, when?: boolean) => {
      if (when || isDirtyState) {
        handleOpenModal(undefined, callback);
      } else {
        callback();
      }
    },
    [message, isDirtyState],
  );

  const cleanupState = () => {
    dispatch(changeIsDirty(false));
  };

  useBlocker(blocker, isDirty);

  return { preventUnmount, cleanupState };
}
