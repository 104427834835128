import axios, { Method } from 'axios';
import config from 'src/config';
import i18n from 'src/i18n';
import store from 'src/redux_store';
import { logoutLocal } from 'src/redux_store/my_account/my_account_slice';
import { toastMessage } from 'src/utils/toast';

type Options = {
  headers?: { [x: string]: string };
  method: Method;
  data?: any;
  params?: any;
  signal?: AbortSignal;
};

export default class ClientBase {
  requestHeaders: { [x: string]: string } = {};
  urlVersion = config.prefix;
  token = '';

  constructor() {}

  setToken = (token: string) => {
    if (token) {
      this.token = token;
    } else {
      this.token = '';
    }
  };

  getBaseRoute = () => {
    return `${config.baseUrl}/${this.urlVersion}`;
  };

  getSystemCameraRoute = () => {
    return `${this.getBaseRoute()}/admin/cameras`;
  };

  getClassifiedListRoute = () => {
    return `${this.getBaseRoute()}/admin/classified_list`;
  };

  getRoleRoute = () => {
    return `${this.getBaseRoute()}/admin/roles`;
  };

  getUserRoute = () => {
    // this.setToken('LneattGE7pxjpSDDNmENSCmD7ojdNPfoW71FacYw');
    return `${this.getBaseRoute()}/admin/users`;
  };

  getPermissionRoute = () => {
    return `${this.getBaseRoute()}/permissions`;
  };

  getUserStationRoute = () => {
    return `${this.getBaseRoute()}`;
  };

  getPoliticalRoute = () => {
    return `${this.getBaseRoute()}/political`;
    // return `/political`;
  };

  getStationRoute = () => {
    return `${this.getBaseRoute()}/admin/stations`;
  };

  getPtzRoute = () => {
    return `${this.getBaseRoute()}/admin/cameras`;
  };

  getVideoRoute = () => {
    return `${this.getBaseRoute()}/admin/video`;
  };

  getNotificationRoute = () => {
    return `${this.getBaseRoute()}/notifications`;
  };

  getBlacklistRoute = () => {
    return `${this.getBaseRoute()}/vehicles`;
  };

  getOptions = (options: Options) => {
    const newOptions: Options = { ...options };

    const headers: { [x: string]: string } = { ...this.requestHeaders };

    if (this.token) {
      Object.assign(headers, newOptions.headers);
    }

    return { ...newOptions, headers };
  };

  doFetch = async <T>(url: string, options: Options): Promise<T> => {
    try {
      const response = await axios<T>(url, { withCredentials: true, ...this.getOptions(options) });

      return response.data;
    } catch (error: any) {
      if (
        error.response?.status &&
        error.response.status === 401
        //  || !error?.response
      ) {
        store.dispatch(logoutLocal());
        toastMessage.error(i18n.t('label.system.autoLogout.info'));
      }

      return Promise.reject(error.response?.data ? error.response.data : error.response);
    }
  };
}
